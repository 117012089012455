<template>
  <div class="box">
    <div class="title" >
      <div class="topBox">
        <span>活动通知</span>
      </div>
      <div class="cursor more" @click="handleMore">
        <span class="moreFont">全部</span>
        <i class="el-icon-arrow-right"></i>
      </div>
    </div>
    <div class="item_box" v-loading='loading'>
      <div class="item" v-for="(item,i) in list" :key='i'>
        <el-image
            @click="toDetail(item,i)"
            class="img cursor"
            style="width: 290px; height:174px"
            :src="item.poster"
            fit="corver"></el-image>
        <div class="time_num">
          <div class="time">{{ item.created_time | secondFormat("LLL") }}</div>
          <div class="num"><img :src="eye_img" alt=""> {{ item.views }}</div>
        </div>
        <div class="tit cursor"  @click="toDetail(item,i)" :title="item.name_en |priorFormat(item.name_zh,LOCALE)">
          {{ item.name_en |priorFormat(item.name_zh,LOCALE) }}
        </div>
        <div class="bottom_loc">
          <img :src="address" alt="">
          <span v-if="item.event_type==1" class="des textOverflow">在线活动</span>
          <span v-if="item.event_type==2" class="des textOverflow" :title="item.address_en | priorFormat(item.address_zh,LOCALE)">{{ item.address_en | priorFormat(item.address_zh,LOCALE) }}</span>

        </div>
      </div>
      <noDataImg v-if="!loading && list.length === 0"></noDataImg>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return{
      loading:false,
      list:[],
      eye_img:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaStatic/img/eye.png",
      address:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaStatic/img/address.png"
    }
  },
  mounted(){
    this.getList()
  },
  methods:{
    async getList(){
      this.loading=true
      let params={
        start:0,
        limit:4,
        is_show:1,
        is_top:1,
        source:this.PJSource,
        user_id:this.USER_INFO.id,
        company_id:this.USER_INFO.company_id
      }
      let res = await this.$store.dispatch("API_index/association_event", params);
      this.loading=false
      if (res.success) {
        this.list=res.data
      }
    },
    handleMore(){
      this.$router.push({
        path: "/activity/list",
      })
    },
    toDetail(item,i){
      this.list[i].views+=1
      let routeUrl = this.$router.resolve({
        path: "/activity/detail",
        query:{
          id:item.id
        }
      });
      window.open(routeUrl.href, "_blank");
    }
  }
}
</script>
<style lang="less" scoped>
.box{
  .title {
    display: flex;
    justify-content: center;
    position: relative;
    font-size: 24px;
    align-items: flex-end;
    padding-top: 32px;
    margin-bottom: 30px;
    .topBox {
      display: flex;
      align-items: flex-end;
      font-weight: 800;
      color: #333333;
      .pillar {
        width: 10px;
        height: 63px;
        background: #7EC9F1;
        margin-right: 20px;
      }
    }
  }
  .more {
    position: absolute;
    right: 0;
    width: 80px;
    height: 30px;
    border-radius: 15px;
    text-align: center;
    line-height: 30px;
    color: #909399;
    font-size: 14px;
  }
  .item_box{
    min-height: 300px;
    position: relative;
    display: flex;
    .item{
      width: 290px;
      height: 316px;
      background: #FFFFFF;
      margin-right: 13px;
    }
    .item:nth-child(4){
      margin-left:0;
    }
    .tit{
      margin: 0 12px;
      padding-top: 10px;
      line-height: 20px;
      word-break: break-all;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      width: 266px;
      height: 51px;
      font-size: 16px;
      font-weight: bold;
      color: #4C4C4C;
      line-height: 22px;
    }
  }
  .time_num{
    padding: 0 12px;
    padding-top: 9px;
    display: flex;
    justify-content: space-between;
    .time{
      height: 17px;
      font-size: 12px;
      font-weight: 400;
      color: #909399;
      line-height: 17px;
    }
    .num{
      height: 17px;
      font-size: 12px;
      font-weight: 400;
      color: #909399;
      line-height: 17px;
      display: flex;
      align-items: center;
      img{
        margin-right:3px;
      }
    }
  }
  .bottom_loc{
    margin-top:22px;
    padding:0 12px;
    display: flex;
    justify-content: space-between;
    img{
      width: 20px;
      height: 20px;
    }
    .des{
      width: 236px;
      height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #345292;
      line-height: 20px;
    }
  }

}
</style>