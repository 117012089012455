<template>
  <div class="container">
     <carouselBanner paddingTop="24%" :bannerType="1"/>
    <div class="content_area">
      <activity />
      <div v-if="advertising1" class="creditBanner">
        <div class="creditBannerImg">
          <div
              class="bannerInner"
              :class="{'pointer': advertising1.link_url && advertising1.extend && !advertising1.extend.is_show }"
              :style="{ 'background-image': 'url(' + advertising1.img_url + ')' }"
              @click="handleDivLink(advertising1)"
          >
            <div
                v-if="advertising1.extend && advertising1.extend.is_show"
                class="bannerBtn"
                :class="'btnStyle' + advertising1.extend.buttonStyle"
                :style="{
                bottom: `${advertising1.extend.bottom}%`,
                right: `${advertising1.extend.right}%`,
                background: advertising1.extend.backgroundColor,
                borderColor: advertising1.extend.borderColor,
              }"
                @click="handleLink(advertising1.link_url)"
            >
              <el-button
                  type="text"
                  :style="{
                  fontSize: `0.${advertising1.extend.fontSize}rem`,
                  color: advertising1.extend.color,
                }"
              >{{ advertising1.extend.content }}</el-button
              >
            </div>
          </div>
        </div>
      </div>
      <schoolCooperation :advertising3="advertising3"/>
      <div v-if="advertising2" class="mobilizationBanner">
        <div class="mobilizationBannerImg">
          <div
              class="bannerInner"
              :class="{'pointer': advertising2.link_url && advertising2.extend && !advertising2.extend.is_show }"
              :style="{ 'background-image': 'url(' + advertising2.img_url + ')' }"
              @click="handleDivLink(advertising2)"
          >
            <div
                v-if="advertising2.extend && advertising2.extend.is_show"
                class="bannerBtn"
                :class="'btnStyle' + advertising2.extend.buttonStyle"
                :style="{
                bottom: `${advertising2.extend.bottom}%`,
                right: `${advertising2.extend.right}%`,
                background: advertising2.extend.backgroundColor,
                borderColor: advertising2.extend.borderColor,
              }"
                @click="handleLink(advertising2.link_url)"
            >
              <el-button
                  type="text"
                  :style="{
                  fontSize: `0.${advertising2.extend.fontSize}rem`,
                  color: advertising2.extend.color,
                }"
              >{{ advertising2.extend.content }}</el-button
              >
            </div>
          </div>
        </div>
      </div>
      <education />
    </div>
    <div class="cooperation">
      <div class="content_area">
        <cooperation />
      </div>
    </div>
  </div>
</template>

<script>
import loginDialog from "~qlc/components/common/loginDialog";
import carouselBanner from '@/baseComponents/carouselBanner'
import activity from '~qlc/components/index/home/activity'
import schoolCooperation from '~qlc/components/index/home/schoolCooperation'
import education from '~qlc/components/index/home/education'
import cooperation from '~qlc/components/index/home/cooperation'
import EventNotification from '~qlc/components/index/home/EventNotification'
export default {
  metaInfo:{
    title:'首页'
  },
  components: {
    loginDialog,
    carouselBanner,
    activity,
    schoolCooperation,
    education,
    cooperation,
    EventNotification
  },
  data() {
    return {
      advertising1: null,
      advertising2: null,
      advertising3: null,
    };
  },
  computed: {},
  created() {
    this.getAdvertisingList()
  },
  methods: {
    async getAdvertisingList() {
      let params = {
        language: this.LOCALE === 'en' ? 1 : 2,
        source: this.PJSource,
        serial_no: '1,2,3'
      }
      let res = await this.$store.dispatch("API_index/getAdvertisingList",params);
      if (res.success) {
        res.data.forEach(item => {
          item.extend = JSON.parse(item.extend);
          if (item.serial_no == 1) {
            this.advertising1 = item
          }
          if (item.serial_no == 2) {
            this.advertising2 = item
          }
          if (item.serial_no == 3) {
            this.advertising3 = item
          }
        })
      }
    },
    handleLink(link) {
      if (!link) return
      if (link.indexOf("http") == "0") {
        window.open(link);
      } else {
        this.$router.push(link);
      }
    },
    handleDivLink(item) {
      if (item.link_url && item.extend && !item.extend.is_show) {
        this.handleLink(item.link_url)
      }
    },
  },
};
</script>
<style scoped lang="less">
  .container {
    background: #FFFFFF;
    .creditBanner {
      margin-top: 24px;
      width: 100%;
      padding-top: 10%;
      position: relative;
      .creditBannerImg {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        .bannerInner {
          height: 100%;
          background-size: 100% 100%;
          position: relative;
          .bannerBtn {
            position: absolute;

            padding: 0px 20px;
            border: 1px solid;
            border-radius: 4px;
            transform: translate(50%, 50%);
            &.btnStyle1 {
              border-radius: 0px;
            }
            &.btnStyle2 {
              border-radius: 4px;
            }
            &.btnStyle3 {
              border-radius: 999px;
            }
          }
        }
      }
    }
    .mobilizationBanner {
      margin-top: 36px;
      width: 100%;
      padding-top: 10.83%;
      position: relative;
      .mobilizationBannerImg {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        .bannerInner {
          height: 100%;
          background-size: 100% 100%;
          position: relative;
          .bannerBtn {
            position: absolute;
            padding: 0px 20px;
            border: 1px solid;
            border-radius: 4px;
            transform: translate(50%, 50%);
            &.btnStyle1 {
              border-radius: 0px;
            }
            &.btnStyle2 {
              border-radius: 4px;
            }
            &.btnStyle3 {
              border-radius: 999px;
            }
          }
        }
      }
    }
    .cooperation {
      margin-top: 36px;
      width: 100%;
      background: url("https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/plc/index%20%E4%BC%9A%E9%95%BF%E5%89%AF%E4%BC%9A%E9%95%BF%E5%8D%95%E4%BD%8Dbg.jpg");
      height: 299px;
      background-size: 100% 100%;
    }
    .pointer {
      cursor: pointer;
    }
  }
  .EventNotification{
    margin-top: 32px;
    width: 100%;
    height: 439px;
    background: url("https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/gbk/2pBg.png");
    background-size: 100% 100%;
  }
</style>
