<template>
  <div class="box">
    <div class="left">
      <div class="title">会长单位</div>
      <div class="list">
        <div
          v-for="(item, index) in dataList"
          :key="index"
          class="item"
        >
          <el-image @click="gotoWebsite(item)" :src="item.img_url" fit="scale-down" class="item"></el-image>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="title">副会长单位</div>
      <div class="list">
        <div class="imgSilder" style="width:780px">
          <el-carousel
            :initial-index="value1"
            :arrow="imgList.length > 1 ? 'always' : 'never'"
            dots="none"
            height="60"
            indicator-position="none"
            trigger="click"
            :loop="true"
          >
            <el-carousel-item
              v-for="(item, index) in imgList"
              :key="index"
              :name="index + ''"
            >
              <div class="demo-carousel clearfix">
                <div
                  class="fl img-wrap"
                  v-for="(ele, index) in item"
                  :key="index"
                >
                  <el-image
                    class="item-img"
                    :src="ele.img_url"
                    fit="scale-down"
                    @click="gotoWebsite(ele)"
                    style="cursor:pointer;"
                  >
                  </el-image>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
          <el-dialog
            fullscreen
            :visible.sync="showDialog"
            class="imgPreivewDialog"
            :show-close="false"
          >
            <el-image
              class="diaLogImage"
              style="width: 0px; height: 0px"
              :src="previewList[0]"
              :preview-src-list="previewList"
              @closePreview="showDialog = false"
              ref="diaLogImage"
            ></el-image>
          </el-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { splitArr } from "~/baseUtils";
export default {
  name: "cooperation",
  data() {
    return {
      dataList: [
        // {
        //   url:
        //     "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/cimage/1.png",
        //   website: "http://www.sinotrans.com/",
        // },
      ],
      dataListWrap:[
        // {
        //   url:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/cimage/sgwl.jpg",
        //   website:"https://sgwl.portshanghai.com.cn/"
        // },
        // {
        //   url:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/cimage/ciewtrans.jpg",
        //   website:"http://www.viewtrans.com/"
        // },
        // {
        //   url:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/cimage/orientil.png",
        //   website:"http://www.orientil.com/"
        // },
        // {
        //   url:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/cimage/ctsfr.png",
        //   website:"https://www.ctsfreight.com/cn/home/index.aspx"
        // },
        // {
        //   url:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/cimage/syslist.bmp",
        //   website:"http://www.syntrans.com/html/"
        // },
        // {
        //   url:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/cimage/jhj.png",
        //   website:"http://www.jhj.com.cn/JHJPortal/main/index.aspx"
        // },
        // {
        //   url:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/cimage/goto.bmp",
        //   website:"ttp://www.gotoubi.com/"
        // },
        // {
        //   url:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/cimage/kerry.jpg",
        //   website:"https://www.kerrylogistics.com/"
        // },
        // {
        //   url:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/cimage/ines.png",
        //   website:"http://lines.coscoshipping.com/home/"
        // },
        // {
        //   url:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/cimage/cosco.png",
        //   website:"http://www.cosco-logistics.com.cn/"
        // },
        // {
        //   url:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/cimage/amass.png",
        //   website:"https://www.amassfreight.com/"
        // },
      ],
      imgList:[],
      showDialog: false,
      previewList:[],
      value1: 0,
    };
  },
  created() {
    this.imgList=splitArr(this.dataListWrap,4)
  },
  mounted() {
    this.presidentList()
    this.vicePresidentList()
  },
  methods: {
    gotoWebsite(item){
      window.open(item.link_url,"_blank")
    },
    async presidentList(){
      try {
        let params={}
        params.source=this.PJSource
        params.genre=5
        let res=await this.$store.dispatch("API_index/getunitTypeList",params)
        if (res.success){
          this.dataList=res.data
        }
      }catch (e) {
      }
    },
    async vicePresidentList(){
      try {
        let params={}
        params.source=this.PJSource
        params.genre=4
        let res=await this.$store.dispatch("API_index/getunitTypeList",params)
        if (res.success){
          this.dataListWrap=res.data
          this.imgList=splitArr(this.dataListWrap,4)
        }
      }catch (e) {
      }
    },
  }
};
</script>

<style scoped lang="less">
.box {
  display: flex;
  padding-top: 59px;
  .left {
    width: 450px;
  }
  .right {
    width: 572px;
  }
  .title {
    font-size: 30px;
    color: #ffffff;
  }
  .list {
    margin-top: 36px;
    .item {
      width: 180px;
      height: 60px;
      background: #ffffff;
      margin-right: 16px;
      cursor: pointer;
    }
    .img {
      width: 100%;
    }
  }
}
.imgPreivewDialog {
  /deep/ .el-dialog {
    border-radius: 0px;
    background: transparent;
  }
  /deep/ .el-dialog__header {
    background: transparent;
    border-radius: 0px;
    border-bottom: 0 none;
  }
}
.imgSilder{
  /deep/ .el-carousel__arrow{
    display:none;
  }
  /deep/ .el-carousel__container{
    height: 60px;
  }
  .img-wrap{
    width: 180px;
    height: 60px;
    background: #fff;
    margin-right: 16px;
    .item-img{
      width: 100%;
      height: 100%;
    }
  }
  .img-wrap:nth-child(4){
    margin-right: 0;
  }
}
</style>
